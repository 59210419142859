import React from 'react'
import Layout from '@components/layouts/default/Layout'
import ServicesWrapper from '@components/ui/wrappers/ServicesWrapper'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'

const Cookie_Statement = ({ intl }) => {
  return (
    <Layout pageName="cookie-statement">
      <ServicesWrapper>
        <h1>{intl.formatMessage({ id: 'cookie-statement.title' })}</h1>
        <p>{intl.formatMessage({ id: 'cookie-statement.cookiesAreSmallData' })}</p>
        <p>{intl.formatMessage({ id: 'cookie-statement.cookiesSetByTheWebsite' })}</p>
        <p>
          {intl.formatMessage({ id: 'cookie-statement.thisCookieStatement' })}
          <Link to="/" className="leftElement">
            www.ideabuddy.com
          </Link>
          {intl.formatMessage({ id: 'cookie-statement.andSubscriptionServices' })}
          <Link to="/privacy-policy" className="leftElement">
            {intl.formatMessage({ id: 'cookie-statement.privacyPolicy' })}
          </Link>
          .
        </p>
        <p>
          {intl.formatMessage({ id: 'cookie-statement.weMayUseCookies' })}
          <Link to="/privacy-policy" className="leftElement">
            {intl.formatMessage({ id: 'cookie-statement.privacyPolicy' })}
          </Link>
          {intl.formatMessage({ id: 'cookie-statement.weMayUpdate' })}
        </p>
        <h2>{intl.formatMessage({ id: 'cookie-statement.whyCookies' })}</h2>
        <ul>
          <li className="marginVertical">{intl.formatMessage({ id: 'cookie-statement.necessaryWebsiteCookies' })}</li>
        </ul>
        <p>{intl.formatMessage({ id: 'cookie-statement.theseCookiesAreRequired' })}</p>
        <ul>
          <li className="marginVertical">{intl.formatMessage({ id: 'cookie-statement.functionalityCookies' })}</li>
        </ul>
        <p>{intl.formatMessage({ id: 'cookie-statement.theseCookies' })}</p>
        <h2>{intl.formatMessage({ id: 'cookie-statement.targetedOnlineAdvertising' })}</h2>
        <p>{intl.formatMessage({ id: 'cookie-statement.weHaveEngaged' })}</p>
        <p>
          {intl.formatMessage({ id: 'cookie-statement.theseThirdParties' })}
          <a href="https://www.aboutads.info/choices" target="_blank" rel="noopener noreferrer" className="leftElement">
            https://www.aboutads.info/choices
          </a>
          .
        </p>
        <p>{intl.formatMessage({ id: 'cookie-statement.ourThirdParty' })}</p>
        <h2>{intl.formatMessage({ id: 'cookie-statement.managingCookies' })}</h2>
        <p>{intl.formatMessage({ id: 'cookie-statement.youHaveTheRight' })}</p>
        <p>{intl.formatMessage({ id: 'cookie-statement.browserControls' })}</p>
        <p>
          {intl.formatMessage({ id: 'cookie-statement.disablingMostInterest' })}
          <a href="https://www.aboutads.info/choices" target="_blank" rel="noopener noreferrer" className="leftElement">
            https://www.aboutads.info/choices
          </a>
          or
          <a href="https://www.youronlinechoices.com" target="_blank" rel="noopener noreferrer" className="leftElement">
            https://www.youronlinechoices.com
          </a>
          .
        </p>
        <p>{intl.formatMessage({ id: 'cookie-statement.mobileAdvertising' })}</p>
        <h2>{intl.formatMessage({ id: 'cookie-statement.ourDetails' })}</h2>
        <ol>
          <li>{intl.formatMessage({ id: 'cookie-statement.thisWebsiteIsOwned' })}</li>
          <li>{intl.formatMessage({ id: 'cookie-statement.weAreRegistered' })}</li>
          <li>{intl.formatMessage({ id: 'cookie-statement.youCanContactUs' })}</li>
        </ol>
      </ServicesWrapper>
    </Layout>
  )
}
export default injectIntl(Cookie_Statement)
